import { EditorSeries } from "../constants/types/seriesTypes";
import { fetchComicUserIdAndCollaboratorIds } from "../utils/firebase/comicsOperations";
import { fetchAllUserSeriesCollaborationsAndCreations } from "../utils/firebase/seriesFetchOperations";
import {
  checkIsShortbreadArtist,
  fetchIsAdminUser,
  fetchIsUserCreatorOfComic,
} from "../utils/firebase/userOperations";

export interface AuthFlags {
  isAdmin: boolean;
  isShortbreadArtist: boolean;
  seriesWithUser: EditorSeries[];
  isCreatorOfAnyComics: boolean;
  loading?: boolean;
}

// Core function to fetch all fundamental auth flags
export const fetchAuthFlags = async (userId: string): Promise<AuthFlags> => {
  try {
    const [isAdmin, isShortbreadArtist, seriesWithUser, isCreatorOfAnyComics] =
      await Promise.all([
        fetchIsAdminUser(userId),
        checkIsShortbreadArtist(userId),
        fetchAllUserSeriesCollaborationsAndCreations(userId),
        fetchIsUserCreatorOfComic(userId),
      ]);

    return {
      isAdmin,
      isShortbreadArtist,
      seriesWithUser,
      isCreatorOfAnyComics,
    };
  } catch (error) {
    console.error("Failed to fetch auth flags:", error);
    // Return safe default values
    return {
      isAdmin: false,
      isShortbreadArtist: false,
      seriesWithUser: [],
      isCreatorOfAnyComics: false,
    };
  }
};

// Derivative authorization functions that use the auth flags
export const getIsAuthorized = (flags: AuthFlags): boolean => {
  return flags.isAdmin || flags.isShortbreadArtist;
};

export const userCanSeeScriptButton = (flags: AuthFlags): boolean => {
  return (
    flags.isAdmin ||
    flags.isShortbreadArtist ||
    flags.seriesWithUser.length > 0 ||
    flags.isCreatorOfAnyComics
  );
};

export const userCanSeeSeriesButton = (flags: AuthFlags): boolean => {
  return userCanSeeScriptButton(flags); // Same logic as script button
};
