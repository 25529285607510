"use client";

import LoadingSpinner from "@/components/ui/custom/LoadingSpinner";
import { User } from "firebase/auth";
import { useRouter } from "next/navigation";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { refreshSessionCookie } from "../app/actions/authActions";
import {
  AuthFlags,
  fetchAuthFlags,
  getIsAuthorized,
  userCanSeeScriptButton,
  userCanSeeSeriesButton,
} from "../lib/authorization";
import { authClient } from "../utils/firebase/firebaseClientConfig";
import {
  setUserUidField
} from "../utils/firebase/userOperations";

interface AuthContextType extends AuthFlags {
  user: User | null;
  isAuthorized: boolean;
  canSeeSeriesButton: boolean;
  canSeeScriptButton: boolean;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  isAuthorized: false,
  canSeeSeriesButton: false,
  canSeeScriptButton: false,
  isAdmin: false,
  isShortbreadArtist: false,
  seriesWithUser: [],
  isCreatorOfAnyComics: false,
});

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [authFlags, setAuthFlags] = useState<AuthFlags>({
    isAdmin: false,
    isShortbreadArtist: false,
    seriesWithUser: [],
    isCreatorOfAnyComics: false,
  });

  useEffect(() => {
    return authClient.onIdTokenChanged(async (user) => {
      if (user) {
        try {
          await refreshSessionCookie(await user.getIdToken(), user.uid);
          setUser(user);
          await setUserUidField(user.uid);

          // Fetch all auth flags at once
          const flags = await fetchAuthFlags(user.uid);
          setAuthFlags(flags);
        } catch (error) {
          console.error("Failed to set user or fetch auth data:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setUser(null);
        setAuthFlags({
          isAdmin: false,
          isShortbreadArtist: false,
          seriesWithUser: [],
          isCreatorOfAnyComics: false,
        });
        setLoading(false);
      }
    });
  }, []);

  // Calculate derivative permissions
  const derivedPermissions = useMemo(
    () => ({
      isAuthorized: authFlags ? getIsAuthorized(authFlags) : false,
      canSeeSeriesButton: authFlags ? userCanSeeSeriesButton(authFlags) : false,
      canSeeScriptButton: authFlags ? userCanSeeScriptButton(authFlags) : false,
    }),
    [authFlags]
  );

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        ...authFlags,
        isAuthorized: derivedPermissions.isAuthorized,
        canSeeSeriesButton: derivedPermissions.canSeeSeriesButton,
        canSeeScriptButton: derivedPermissions.canSeeScriptButton,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);

export function withAuth<P extends object>(
  WrappedComponent: React.ComponentType<P & { user: User }>
) {
  return function WithAuth(props: Omit<P, "user">) {
    const { user, loading } = useAuth();
    const router = useRouter();

    useEffect(() => {
      if (!loading && !user) {
        router.push("/login");
      }
    }, [user, loading, router]);

    if (loading || !user) {
      return <LoadingSpinner />;
    }

    return <WrappedComponent {...(props as P)} user={user} />;
  };
}
