import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: `${process.env.NEXT_PUBLIC_API_KEY}`,
  authDomain: `${process.env.NEXT_PUBLIC_AUTH_DOMAIN}`,
  projectId: `${process.env.NEXT_PUBLIC_PROJECT_ID}`,
  storageBucket: `${process.env.NEXT_PUBLIC_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.NEXT_PUBLIC_MESSAGING_SENDING_ID}`,
  appId: `${process.env.NEXT_PUBLIC_APP_ID}`,
  measurementId: `${process.env.NEXT_PUBLIC_MEASUREMENT_ID}`,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, "shortbread");

// Initialize the FirebaseUI Widget using Firebase.
export const authClient = getAuth(app);
export const provider = new GoogleAuthProvider();
// Initialize Cloud Firestore and get a reference to the service
const editorDatabase = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});

export default editorDatabase;
export { app };

export const storage = getStorage(app);

export const FIREBASE_IN_COMPARISON_VALUE_LIMIT = 30;

export const GOOGLE_STORAGE_BASE_URL = "https://storage.googleapis.com/";
export const STORAGE_BUCKET_BASE_URL = `${
  GOOGLE_STORAGE_BASE_URL + firebaseConfig.storageBucket
}/`;
export const SAVED_COMICS_COLLECTION = "comicsSave";
export const BACKGROUND_COLLECTION = "background_textures";
export const GPT_COMIC_GENERATION_TASKS_COLLECTION =
  "gpt_comic_generation_tasks";
export const GENERATION_OUTPUT_COLLECTION = "editor_generation_outputs";
export const WIZARD_GENERATION_OUTPUT_COLLECTION = "wizard_generation_outputs";
export const USERS_COLLECTION = "users";
export const SCRIPTS_COLLECTION = "scripts";
export const SHORTBREAD_ARTISTS_COLLECTION = "shortbreadArtists";
export const CHARACTERS_COLLECTION = "characters";
export const CHARACTER_NAME_COLLECTION = "characterNames";
export const NOTIFICATION_SIGNUP_COLLECTION = "notificationSignups";
export const ADMIN_USERS_COLLECTION = "adminUsers";
export const USERS_PUBLISH_COLLECTION = "usersPublish";
export const REMOVE_BACKGROUND_COLLECTION = "comic_rembg_generation";
export const STORYBOARD_COLLECTION = "storyboards"; // PanelStudio canvases used to be called "storyboards"
export const LORA_CREATION_JOB_COLLECTION = "loraCreationJobs";

export const EDITOR_GENERATION_OUTPUTS_COLLECTION = "editor_generation_outputs";

export const CHARACTER_TRAIN_GENERATION_OUTPUTS_COLLECTION =
  "characterTrainGenerationOutputs";

export const SERIES_COLLECTION = "comicSeries";

export const EPISODES_COLLECTION = "comicEpisodes";
export const SERIES_TAGS_COLLECTION = "comicSeriesTags";
